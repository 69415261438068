export default function animatedScroll(fromY: number, toY: number, duration: number, func: (timeProgressRate: number) => number) {
  if (!window.requestAnimationFrame) {
    setTimeout(function () {
      scrollTo(0, toY);
    }, duration);
    return;
  }
  let startTimestamp: number | undefined = undefined;
  function _step(timestamp: number) {
    if (!startTimestamp) startTimestamp = timestamp;

    let progress = timestamp - startTimestamp;
    if (progress > duration) progress = duration;

    const timeProgressRate = progress / duration;
    const animationProgressRate = func(timeProgressRate)

    scrollTo(0, fromY + (toY - fromY) * animationProgressRate);

    if (progress < duration) {
      requestAnimationFrame(_step);
    }
  }

  requestAnimationFrame(_step);
}

export function easeInOutCubic(timeProgressRate: number): number {
  return timeProgressRate < 0.5 ? Math.pow(timeProgressRate, 3) * 4 : Math.pow(timeProgressRate - 1, 3) * 4 + 1
}
