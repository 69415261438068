// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contacts-complete-tsx": () => import("./../../../src/pages/contacts-complete.tsx" /* webpackChunkName: "component---src-pages-contacts-complete-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-corporate-profile-tsx": () => import("./../../../src/pages/corporate-profile.tsx" /* webpackChunkName: "component---src-pages-corporate-profile-tsx" */),
  "component---src-pages-devops-github-tsx": () => import("./../../../src/pages/devops-github.tsx" /* webpackChunkName: "component---src-pages-devops-github-tsx" */),
  "component---src-pages-hackathon-tsx": () => import("./../../../src/pages/hackathon.tsx" /* webpackChunkName: "component---src-pages-hackathon-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-post-tsx": () => import("./../../../src/pages/post.tsx" /* webpackChunkName: "component---src-pages-post-tsx" */),
  "component---src-pages-posts-tsx": () => import("./../../../src/pages/posts.tsx" /* webpackChunkName: "component---src-pages-posts-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-zen-advisor-tsx": () => import("./../../../src/pages/zen-advisor.tsx" /* webpackChunkName: "component---src-pages-zen-advisor-tsx" */)
}

