import React from "react"
import {
  createGlobalStyle,
  DefaultTheme,
  ThemeProvider,
} from "styled-components"
import Navigation from "@/components/Navigation"
import Helmet from "react-helmet"
import Footer from "@/components/Footer"
import { PageRendererProps } from "gatsby"
import { config, dom } from "@fortawesome/fontawesome-svg-core"

config.autoAddCss = false

const ResetStyle = createGlobalStyle`
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
}

body {
  line-height:1;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
  display:block;
}

nav ul {
  list-style:none;
}

blockquote, q {
  quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content:'';
  content:none;
}

a {
  margin:0;
  padding:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
}

/* change colours to suit your needs */
ins {
  background-color:#ff9;
  color:#000;
  text-decoration:none;
}

/* change colours to suit your needs */
mark {
  background-color:#ff9;
  color:#000;
  font-style:italic;
  font-weight:bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom:1px dotted;
  cursor:help;
}

table {
  border-collapse:collapse;
  border-spacing:0;
}

/* change border colour to suit your needs */
hr {
  display:block;
  height:1px;
  border:0;
  border-top:1px solid #cccccc;
  margin:1em 0;
  padding:0;
}

input, select {
  vertical-align:middle;
}
`

const GlobalStyle = createGlobalStyle`
  html {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    background-color: #000;
  }
  html, body {
    height: 100%;
  }
  body {
    font-family: "Roboto", "Noto Sans JP", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-feature-settings: "pkna";
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
  }
  @keyframes onAutoFillStart { from {} to {}}
  input:-webkit-autofill {
      animation-name: onAutoFillStart;
      transition: background-color 50000s ease-in-out 0s;
  }
  * {
    box-sizing: border-box;
  }
  /* https://github.com/gatsbyjs/gatsby/issues/7310 */
  #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
  }
`

const theme: DefaultTheme = {
  maxXSDown: "(max-width: 575px)",
  minSmUp: "(min-width: 576px)",
  maxSmDown: "(max-width: 767px)",
  minMdUp: "(min-width: 768px)",
  maxMdDown: "(max-width: 991px)",
  minLgUp: "(min-width: 992px)",
  maxLgDown: "(max-width: 1199px)",
  minXLUp: "(min-width: 1200px)",
  primaryColor: "#128DAD",
  primaryDarkColor: "#03395d",
  secondaryColor: "#a1a1a1",
  secondaryDarkColor: "#777",
  secondaryLightColor: "#f5f5f5",
  textColor: "#333",
  textLightColor: "#ffffff",
}

interface LayoutProps {
  location: PageRendererProps["location"]
}

const Layout: React.FC<LayoutProps> = ({ location, children }) => {
  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=no"
        />

        {/* Google Fontsの方がtypefaceより読み込みが早い */}
        <link
          href="https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400,700|Teko:400,700|Roboto:300,400,700"
          rel="stylesheet"
          type="text/css"
        />
        <style type="text/css">{dom.css()}</style>
      </Helmet>
      <ResetStyle />
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Navigation location={location} />
        {children}
        <Footer />
      </ThemeProvider>
    </>
  )
}

export default Layout
